import yszcIcon from '@/assets/images/product/yszc-icon.png'
import dzjqrIcon from '@/assets/images/product/dzjqr-icon.png'

export const navList = [
  { key: '/home', title: '首页', icon: 'product-center' },
  { key: '/sjsgshm', title: '工商核名', icon: 'product-center' },
  { key: '/sjsyszc', title: '注册公司', icon: 'product-center' },
  { key: '/sjsdzjqr', title: '自助报税', icon: 'product-center' },
  // {
  //   key: '/sjsyszc',
  //   title: '十角兽',
  //   icon: 'product-center',
  //   children: [
  //     {
  //       key: '/sjsyszc',
  //       title: '注册公司',
  //       img: require('@/assets/images/product/yszc-icon.png'),
  //     },
  //     {
  //       key: '/sjsdzjqr',
  //       title: '记账报税',
  //       img: require('@/assets/images/product/dzjqr-icon.png'),
  //     },
  //   ],
  // },
  {
    key: '/solution',
    title: '解决方案',
    icon: 'product-center',
    children: [
      {
        key: '/solution/bank',
        title: '银行',
        img: require('@/assets/images/solution/bank-nav-icon.png'),
      },
      {
        key: '/solution/customer',
        title: '客户',
        img: require('@/assets/images/solution/customer-nav-icon.png'),
      },
      {
        key: '/solution/partner',
        title: '合伙人',
        img: require('@/assets/images/solution/partner-nav-icon.png'),
      },
    ],
  },
  // { key: '/partner', title: '招募合伙人', icon: 'product-center' },
  { key: '/news', title: '资讯动态', icon: 'news' },
  // { key: '/case', title: '成功案例', icon: 'product-center' },
  { key: '/about', title: '关于我们', icon: 'about' },
  // { key: '/shortLink', title: '云上注册', icon: 'product-center' },
]
export const bannerInfo = {
  title: '小微企业 记账报税新时代',
  subTitle:
    '在科技技术驱动下，财税服务花费成本真的不用这么高，用1/4的价格，享受120%的服务体验',
}
export const syBannerInfo = {
  title: '十角兽',
  subTitle: '助力中小微企业腾飞',
  slogan: '公司致力于提供精准化、场景化、数字化的工商财税服务新体验',
}
export const qmInfo = {
  title: '开公司，先得起个好名字',
  subTitle: '一个好名字，让大众影响深刻，企业发展事半功倍',
  txt1: '智能算法',
  txt2: '秒出结果',
}
export const cmInfo = {
  title: '只需简单两步，为公司名称测试打分',
  subTitle: '推演八卦，公司名字的吉凶乃长期影响公司运',
  txt1: '内涵解读',
  txt2: '预知吉凶',
}
export const hmInfo = {
  title: '企业在线免费核名',
  subTitle: '只有工商局审核通过的公司名才能使用',
  txt1: '工商直连',
  txt2: '通过率高',
}
export const productInfo = {
  title1: '十角兽云上注册',
  subTitle1: '足不出户，线上申请，快速拿证',
  step1: ['填写信息', '核查材料', '证照认证', '工商审核', '领取执照'],
  title2: '十角兽代账机器人',
  subTitle2: '轻松三步，自助完成记账报税 每年立省',
  step2: ['核对票据', '确认税额', '一键报税'],
}
export const qyhmInfo = {
  title1: '十角兽企业核名',
  subTitle1: '工商官网直连，快速核名',
  step1: ['填写信息', '工商核名', '反馈结果'],
}
export const advantages = [
  {
    key: 1,
    title: '更低的价格',
    subTitle:
      '低至<strong style="color: #000000;">600元/年</strong>，价格透明，产品定期迭代免费使用',
    icon: 'price',
  },
  {
    key: 2,
    title: '更好的服务',
    subTitle: '深耕行业20余年，专注小微企业，顾问在线陪同服务',
    icon: 'service',
  },
  {
    key: 3,
    title: '更简单易操作',
    subTitle: '无需会计基础，系统智能引导处理，3步完成记账报税',
    icon: 'option',
  },
]

export const sideTools = [
  {
    key: 1,
    title: '微信沟通',
    icon: 'side-icon1',
  },
  {
    key: 2,
    title: '在线客服',
    icon: 'side-icon2',
  },
  // {
  //   key: 3,
  //   title: '公众号',
  //   icon: 'side-wechat',
  // },
]
const questionListOneBg = require('@/assets/images/question-list-one.png')
export const question = {
  title: '创业期的您，是否面临这些问题？',
  bg: require('@/assets/images/question-bg.png'),
  list: [
    {
      key: 1,
      title: '公司体量小，没有专职会计，需要记账报税服务。',
      icon: 'question',
      bg: questionListOneBg,
    },
    {
      key: 2,
      title: '代账公司繁多，服务价格千变万化，选择恐惧症。',
      icon: 'question',
      bg: questionListOneBg,
    },
    {
      key: 3,
      title: '每个月业务量不大，一年花费两三千代理记账费，心疼。',
      icon: 'question',
      bg: questionListOneBg,
    },
    {
      key: 4,
      title: '对代账公司不太满意，想自己做又不知从何下手。',
      icon: 'question',
      bg: questionListOneBg,
    },
  ],
}

export const introduce = {
  title: '十角兽财税服务来啦！',
  tradition: {
    title: '传统代账公司',
    type: '小规模 / 个体户',
    price: '<strong style="color: #2761FF">2400 - 3600</strong> 元 / 年',
    icon: 'tradition',
  },
  current: {
    title: '十角兽',
    type: '小规模 / 个体户',
    price: '<strong style="color: #FDE82F">600</strong> 元 / 年',
    icon: 'current',
  },
  pkIcon: 'pk',
  pkBg: require('@/assets/images/introduce-pk-bg.png'),
  bg: require('@/assets/images/introduce-bg.png'),
  special: require('@/assets/images/introduce-special.png'),
  advantagesIcon: 'right',
  advantages: [
    '价格优势明显',
    '专业服务保障 ',
    '无需会计基础',
    '自动计税报税',
    '操作简单便捷',
    '一分钟数据迁移',
    '7*24小时在线服务',
    '风险智能预警',
    '非接触式办税',
    '政策自动更新',
  ],
  mAdvantages: [
    '价格优势明显',
    '专业服务保障 ',
    '操作简单便捷',
    '政策自动更新',
    '全程在线服务',
    '风险智能预警',
    '非接触式办税',
    '一分钟数据迁移',
  ],
}

export const stepInfo = {
  title: '仅需三步，即可完成记账报税！',
  stepBg: require('@/assets/images/step-bg.png'),
  stepCustomerVoiceBg: require('@/assets/images/step-custom-voice-bg.png'),
  steps: [
    {
      key: 1,
      title: '扫码/拍照',
      act: '上传票据',
      icon: 'upload-ticket',
    },
    {
      key: 2,
      title: '十角兽',
      act: '财税中心做账',
      icon: 'financial-and-taxation',
    },
    {
      key: 3,
      title: '客户确认',
      act: '税金',
      icon: 'taxation',
    },
    {
      key: 4,
      title: '完成当期',
      act: '记账报税',
      icon: 'account-book-tax',
    },
  ],
  customerTitle: '客户心声',
  customerMsg: [
    {
      key: 1,
      avatar: '',
      customer: '安徽 - 俞先生',
      msg: '刚开始为注册公司发愁，完全不知道要准备什么材料，跑了好几次工商局都没办下来营业执照。后来用了十角兽云上注册小程序，3天营业执照就下来了，非常方便。而且十角兽还支持自己记账报税，一个月十几张票，几分钟就完成报税了，比自己找代账公司还省了不少钱，很满意。',
    },
    {
      key: 2,
      avatar: '',
      customer: '上海 - 陈先生',
      msg: '我们以前找了本地一家比较大的代账公司，但可能因为我们是小公司吧，总感觉代账会计信息回复得慢，很少联系我们。后来听说了十角兽代账机器人，抱着试试看的心态使用了下，刚开始还担心不会操作，但是十角兽的客服很热心，教了不到20分钟，自己就能完成记账报税操作了。现在每个月用十角兽记账报税，并且数据和业务进度自己随时都能看到，很放心。',
    },
    {
      key: 3,
      avatar: '',
      customer: '深圳 - 潘先生',
      msg: '十角兽代账机器人这款小程序太好用了，我每月点点就把税报了，超级方便。在用这款产品前，我们家企业业务量不大，每月都没几张票，找代账公司报税价格比较高，所以我每月都让我学财务的朋友帮忙报，后来无意间在微信上搜代账，搜到了十角兽代账机器人，尝试了用一下 我就点点税就报了，我让我财务的朋友还看了一下这款产品，把我朋友都惊到了，他都感觉他要失业了。',
    },
  ],
  customerLeftIcon: 'customer-slide-left',
  customerRightIcon: 'customer-slide-right',
}

export const characterInfo = {
  title: '专注小微企业财税服务',
  list: [
    {
      key: 1,
      title: '票据审核',
      icon: 'bill-review',
    },
    {
      key: 2,
      title: '智能记账',
      icon: 'intelligent-accounting',
    },
    {
      key: 3,
      title: '纳税申报',
      icon: 'tax-return',
    },
    {
      key: 4,
      title: '在线查账',
      icon: 'online-check',
    },
    {
      key: 5,
      title: '工商年检',
      icon: 'industrial-and-commercial-inspection',
    },
    {
      key: 6,
      title: '汇算清缴',
      icon: 'payment',
    },
    {
      key: 7,
      title: '财税咨询',
      icon: 'fiscal-consultation',
    },
    {
      key: 8,
      title: '发票管理',
      icon: 'invoice-management',
    },
  ],
}

export const contactUsInfo = {
  title: '十角兽智能财税',
  description: '提交公司信息预约体验',
}

export const footerInfo = {
  logo: 'footer-logo',
  base: [
    {
      key: '地址：',
      value: '南京市江宁区紫金研创中心5号楼',
    },
    {
      key: '邮箱：',
      value: 'marketing@shijiaoshou.cn',
    },
    {
      key: '电话：',
      value: '400-060-7065',
    },
  ],
  imgs: [
    {
      src: 'footer-gzh',
      title: '关注公众号',
    },
  ],
}
export const newsList = [
  {
    key: '1',
    title: '远程辅导中心可及时答复纳税人网上办税咨询',
    content:
      '“三方协议可以在网上自行进行办理，请您登录山东省电子税务局网站后，搜索‘存款’…',
    time: '2021- 08 - 19:30:00',
    read: '2034',
  },
  {
    key: '2',
    title: '4万份问卷背后：全面查找疫情防控常态化下纳税人难点痛点和堵点',
    content:
      '“您认为哪项税费业务的办理最需要进一步优化、简化？”“您对税务部门在办理发票申领等高频事项方面评价如何？”……近期，南京市栖霞区税务局向社会各界和广大纳税人缴费人发放4万份《税费服务需求调查问卷》…',
    time: '2021- 08 - 19:07:00',
    read: '2321',
  },
  {
    key: '3',
    title:
      '国家税务总局关于小型微利企业和个体工商户延缓缴纳2020年所得税有关事项的公告',
    content:
      '为进一步支持小型微利企业和个体工商户复工复产，缓解其生产经营资金压力，激发市场主体活力，现就小型微利企业和个体工商户延缓缴纳2020年所得税有关事项公告如下…',
    time: '2021- 08 - 17:33:00',
    read: '1652',
  },
  {
    key: '4',
    title: '注意：您的专属减税降费政策已送达，快查收～',
    content:
      '为进一步支持小型微利企业和个体工商户复工复产，缓解其生产经营资金压力，激发市场主体活力，现就小型微利企业和个体工商户延缓缴纳2020年所得税有关事项公告如下…',
    time: '2021- 08 - 17:33:00',
    read: '2964',
  },
  {
    key: '5',
    title: '小型微利企业和个体工商户如何享受企业所得税优惠政策？ ',
    content:
      '为了推进办税便利化改革，小型微利企业统一实行按季度预缴企业所得税。小型微利企业在预缴和汇算清缴所得税时均可享受优惠政策，享受政策时无需备案，通过填写企业所得税纳税申报表相关栏次，即可享受…',
    time: '2021- 08 - 17:00:00',
    read: '2317',
  },
  {
    key: '6',
    title:
      '电子税务局办税攻略 ▏申报残保金时没有享受小微免征政策怎么办？（8月第十三期） ',
    content:
      '根据国家税务总局发布的《关于增值税 消费税与附加税费申报表整合有关事项的公告》（国家税务总局公告2021年第20号）要求，明确自2021年8月1日起，全面推行增值税、消费税分别与附加税费申报表整合工作…',
    time: '2021- 08 - 15:16:00',
    read: '2567',
  },
]
export const newsArticle = [
  `
  “三方协议可以在网上自行进行办理，请您登录山东省电子税务局网站后，搜索‘存款’……”8月16日上午，国家税务总局德州经济技术开发区第一税务分局（办税服务厅）的远程辅导员许静，正认真答复纳税人网上办税咨询。 
  <br>
  <br>
   以往，征期最后一天是办税服务厅最繁忙的时间，但记者10点到此采访，却看到以往30个服务窗口只开通了5个，纳税人几乎不用排队等待就能有序办理，其它改为潮汐窗口和网上办税辅导窗口，引导纳税人采用山东省电子税务局网站进行“非接触式”办税。
  <br>
  <br>
  “疫情期间，我们加大了对‘非接触式’办税缴费的推广力度，引导纳税人和缴费人非必要不进厅，并上线‘智能办税’微信小程序，提供进厅预约办税，实现了18项高频进厅事项的‘掌上’办理。同时，我们还组建线上服务中心，通过空中客服为纳税人提供远程辅导，及时解决办理过程中遇到的各类问题，既方便又安全。”办税服务厅负责人崔涛介绍。
  <br>
  <br>
  在大厅网上自助办税区，后台审批员张淑盼正在为纳税人网上申请的业务核对信息、办理邮寄发票。“以往经营业户需要到办税服务厅或者到自助网点领取发票，现在可以通过网上进行预约，由邮政快递免费送发票上门，大大方便了纳税人。”张淑盼说。
  <br>
  <br>
  德州经济技术开发区税务局还加大对“非接触式”办税的引导力度，通过微信群、“鲁税通”征纳互动平台、纳税人学堂等方式，积极引导纳税人通过电子税务局进行网上办税，通过24小时自助终端进行自助办税，最大限度地进行线上办理，既提高了工作效率，又避免了纳税人和税务人员交叉感染的风险。
  `,

  `
  “您认为哪项税费业务的办理最需要进一步优化、简化？”“您对税务部门在办理发票申领等高频事项方面评价如何？”……近期，南京市栖霞区税务局向社会各界和广大纳税人缴费人发放4万份《税费服务需求调查问卷》，并通过电话、征纳沟通平台、钉钉直播等方式，进行全方位的互动交流，全面查找疫情防控常态化下纳税人缴费人反映强烈的“难点”“痛点”“堵点”。
  <br>
  <br>
  问计于需，现实难题“快捷”办理
  <br>
  <br>
  会计肖海艳由于办理业务需要，隔三差五就要来税务局办事。令她头疼的是，由于流程规定，有时候办理一项业务要在管理科和办税服务厅多次往返，这两个部门又分属不同的楼栋，费时费力。近期，当她再次来到税务局，惊喜地发现，只需跑一次就可以办完全部业务。“现在进一个门、在一个窗口就能办完整个流程，办税缴费的体验感越来越好了。”肖海艳很是高兴。
  <br>
  <br>
  获得感的不断提升，得益于南京市栖霞区税务局的“税费服务”大调查。调查中了解到，不少纳税人同肖海艳一样，在办理行政处罚、发票约谈等业务时，往往要在管理科与办税服务厅之间“两头跑”，希望能更加方便快捷地办理有关业务。
  <br>
  <br>
  针对纳税人缴费人的诉求，南京市栖霞区税务局创新工作思路，抽调业务骨干组建专业工作团队，实行简易行政处罚、发票线下约谈、一般股权转让等相关业务集中办理，既节省办税时间，又提高办税效率。记者跟踪测算，办理一户常规的简易处罚，从纳税人进门到办完业务，耗时不超过15分钟，比以往至少缩短了50%的办税时间。
  <br>
  <br>
  记者在办税服务厅看到，区域功能按全职能和个性服务进行科学划分。在个体办税厅，可以实现个体工商户新办、核票、变更、退税、投诉、注销一条龙服务。“针对一些个体工商户业务不熟练的情况，由我们的业务骨干辅导他们填写表证单书，‘手把手’为他们讲解办税步骤，提供‘一对一’服务。”大厅值班员徐晟雄介绍说。
  <br>
  <br>
  调查报告显示，42%的纳税人缴费人希望新办户审批能再快一点，流程能再优化一些。对此，南京市栖霞区税务局积极呼应，联合行政审批部门联动推出“全链条”式服务举措，实现营业执照、税种认定、制度备案和发票领用等12项涉税事项一小时内“一次办成”，助力疫情期间新办企业开业创业。
  <br>
  <br>
  <br>
  <br>
  
  近日，南京浩宙建筑装潢有限公司的房会计到栖霞区行政审批局领取营业执照，在税务工作人员的指导下，通过江苏省电子税务局申请增加税务新办套餐。“原以为新办企业的手续复杂、审核时间长，没想到现在这么方便，领取营业执照的当天就申请了税务新办套餐。”房会计感叹地说。据统计，自实施“全链条”服务以来，栖霞区税务局共计办理新办企业套餐5627户，平均办结时长9分钟，准期办结率达到100%。
  <br>
  <br>
  问政于企，政策辅导“精准”到位
  <br>
  <br>
  “税费服务”大调查中，人们对税务部门的工作进行了评价。其中，特服号以99.8%的满意率获得了所有单项工作的评分榜首。
  <br>
  <br>
  疫情防控期间，特服号8个坐席的12名姑娘们践行着“服务每一天、用心每一秒”的约定，为纳税人缴费人提供税费咨询、接收投诉举报、收集意见建议等服务，申报期最高峰时每人每天要接听100多个来电。
  <br>
  <br>
  <br>
  <br>
  
  在特服号负责人周鹏看来，特服号身处服务纳税人缴费人的最前沿，也是税收工作的“风向标”。他实时关注着话务监控系统中动态变换的曲线和数字，监控整个热线的运行情况，科学安排、统筹预判，灵活调整峰谷时段上线咨询员数量，确保接通率和纳税人的咨询体验感。在将最新税收政策传递给纳税人缴费人的同时，他还建立了办税服务厅、特服号每日联系制度，做好预约服务、邮寄服务的受理登记、传递。加强与税政、征管、纳服等部门的沟通协调，针对咨询热点，统一答复口径。通过各种方式，确保纳税人关注的问题第一时间得到解决。
  <br>
  <br>
  为深入贯彻落实“放管服”改革，加大服务企业力度，栖霞区税务局将特服号专家团队进行了扩容，组建了“一企来”税务政策专员团队，主动对接政务服务江苏12345“一企来”企业服务热线。
  <br>
  <br>
  “多亏了咨询专家们的耐心指导，我的美术培训机构已经顺利开张啦！”一家美术培训机构负责人对“一企来”税务政策专员团队称赞满满。他表示，自己有意成立一家美术培训机构，但对新办企业的设立流程、所需提交的资料、涉及的行政部门等信息一无所知，情急之下，拨打了“一企来”服务热线。“本来我以为办理完所有事项很繁琐，没想到‘一企来’专家们解决了我所有的问题，‘一企来’就是‘一起来’解决难题的意思吧！”
  <br>
  <br>
  “一企来”服务热线有效解决了“政策资源分散、群众咨询多头跑”的问题，建立了企业诉求记录归集、聚类分析、辅助决策机制，确保“接得更快、分得更细、答得更准”，真正实现纳税人缴费人“事事有着落，件件有回复”。
  <br>
  <br>
  问策于难，“非接触式”办税“常态”推广
  <br>
  <br>
  面对此轮疫情中来势汹汹的“德尔塔”变异株，纳税人缴费人在《税费服务需求调查问卷》中，对如何安全办理税费等事项十分关注。为进一步回应纳税人缴费人“急难愁盼”问题，栖霞区税务局及时梳理申报期延期、非接触式办税宣传以及办税服务厅疫情防控等十余条非接触式办税提醒，并调整问卷内容，第一时间送到纳税人缴费人手中。同时，落实“非必要不出门、非必要不聚集”的防控要求，积极推广“江苏税务APP”等办税新渠道，全方位立体式拓宽办税方式，为纳税人缴费人提供更加安全优质高效的办税服务。
  <br>
  <br>
  “疫情以来，我们根据《调查问卷》反馈的情况，不断加大‘非接触式’办税缴费的推广力度，网上办税已成为广大纳税人缴费人的办税新习惯，再加上江苏税务微信公众号、江苏税务APP的推广应用，纳税人缴费人通过一部手机就能完成92项高频业务事项，‘网上办、掌上办’成为常态。”纳服科副科长潘媛说。
  <br>
  <br>
  <br>
  <br>
  
  “我住在江宁区，现在不能出小区，我们公司急着注销，怎么办？”疫情期间，一份问卷回复通过“钉钉”平台传到纳服科工作人员手中。南京欧亨医疗科技有限公司的财务负责人张女士反映由于疫情防控的要求，无法到办税服务厅办理注销业务。
  <br>
  <br>
  “您好，张女士，针对您在问卷调查中反映的问题，我们将按照‘非接触式’办税方式为您办理注销。”在线税务人员及时与张女士对接，把需要填写的表证单书电子档发送给张女士，通过电话远程沟通，辅导注销所需材料。收到张女士邮寄的材料后，注销专窗工作人员当天下午就办理完成，并第一时间把“清税证明”邮寄给了张女士。收到“清税报告”，张女士心里的一块石头落了地。
  <br>
  <br>
  和张女士一样享受到“非接触式”办税便利的，还有南京仙林鼓楼医院投资管理有限公司。疫情期间企业资金压力很大，他们在问卷上提出希望提高增值税留抵退税审批速度，以缓解企业资金周转压力，助力企业抗击疫情。面对企业的需求，栖霞区税务人员及时辅导、网上申请、快速审核、快速审批，将每一个环节时间尽可能压缩，让退税资金快速“跑”到企业账户。152.49万元的税款“及时雨”为南京仙林鼓楼医院投资管理有限公司的后续发展注入了生机和活力，而“不见面审批”则为纳税人提供了便捷、高效的办税途径，真正实现全程网办“零跑腿”，政务服务“零距离”。
  `,

  `
  为进一步支持小型微利企业和个体工商户复工复产，缓解其生产经营资金压力，激发市场主体活力，现就小型微利企业和个体工商户延缓缴纳2020年所得税有关事项公告如下：
  <br>
  <br>
  一、小型微利企业所得税延缓缴纳政策
  <br>
  <br>
  2020年5月1日至2020年12月31日，小型微利企业在2020年剩余申报期按规定办理预缴申报后，可以暂缓缴纳当期的企业所得税，延迟至2021年首个申报期内一并缴纳。在预缴申报时，小型微利企业通过填写预缴纳税申报表相关行次，即可享受小型微利企业所得税延缓缴纳政策。
  <br>
  <br>
  本公告所称小型微利企业是指符合《国家税务总局关于实施小型微利企业普惠性所得税减免政策有关问题的公告》（2019年第2号）规定条件的企业。
  <br>
  <br>
  二、个体工商户所得税延缓缴纳政策
  <br>
  <br>
  2020年5月1日至2020年12月31日，个体工商户在2020年剩余申报期按规定办理个人所得税经营所得纳税申报后，可以暂缓缴纳当期的个人所得税，延迟至2021年首个申报期内一并缴纳。其中，个体工商户实行简易申报的，2020年5月1日至2020年12月31日期间暂不扣划个人所得税，延迟至2021年首个申报期内一并划缴。
  <br>
  <br>
  本公告自2020年5月1日起施行。5月1日至本公告发布前，纳税人已经缴纳符合本公告规定缓缴税款的，可申请退还，一并至2021年首个申报期内缴纳。
  <br>
  <br>
  特此公告。
  <br>
  <br>
  政策解读
  <br>
  <br>
  经商财政部，税务总局发布了《关于小型微利企业和个体工商户延缓缴纳2020年所得税有关事项的公告》（以下简称《公告》）。现解读如下：
  <br>
  <br>
  一、制定《公告》背景
  <br>
  <br>
  为贯彻落实党中央、国务院决策部署，进一步鼓励小型微利企业和个体工商户复工复产，缓解其经营资金压力，更大力度帮助企业渡难关，税务总局制发《公告》，明确小型微利企业和个体工商户可延缓缴纳所得税。
  <br>
  <br>
  二、《公告》内容解读
  <br>
  <br>
  （一）小型微利企业所得税延缓缴纳政策
  <br>
  <br>
  1.明确小型微利企业所得税延缓缴纳政策的适用范围。无论实行查账征收方式还是核定征收方式的企业，只要符合小型微利企业条件，均可以享受企业所得税延缓缴纳政策。
  <br>
  <br>
  2.明确预缴申报时小型微利企业的判断方法。根据《国家税务总局关于实施小型微利企业普惠性所得税减免政策有关问题的公告》（2019年第2号）规定，自2019年度起，在预缴企业所得税时，企业可直接按当年度截至本期末的资产总额、从业人数、应纳税所得额等情况判断是否为小型微利企业。小型微利企业享受企业所得税延缓缴纳政策时，仍然沿用上述判断方法。预缴申报时符合小型微利企业条件的企业，既可以享受小型微利企业普惠性所得税减免政策，也可以同时享受企业所得税延缓缴纳政策。
  <br>
  <br>
  3.明确小型微利企业所得税延缓缴纳政策的时间要求。小型微利企业在2020年剩余申报期完成预缴申报后，可暂不缴纳当期的企业所得税款，延迟至2021年首个申报期内一并缴纳。
  <br>
  <br>
  4.明确享受小型微利企业所得税延缓缴纳政策的办理方式。为减轻纳税人办税负担，便利纳税人操作，小型微利企业所得税延缓缴纳政策采用“自行判别、申报享受”的办理方式。企业根据经营情况以及相关税收规定自行判断是否符合小型微利企业的条件，并自主选择是否享受延缓缴纳政策。符合条件且选择享受延缓缴纳政策的小型微利企业，自行计算延缓缴纳税额，并通过填报企业所得税预缴纳税申报表享受延缓缴纳政策。
  <br>
  <br>
  （二）个体工商户所得税延缓缴纳政策
  <br>
  <br>
  一是个体工商户延缓缴纳个人所得税政策的享受范围。无论实行查账征收方式还是核定征收方式的个体工商户，均可对2020年5月1日至2020年12月31日申报期内按规定缴纳的经营所得个人所得税，在办理经营所得纳税申报后享受延缓缴纳政策。
  <br>
  <br>
  二是实行简易申报方式的定期定额个体工商户享受方式。个体工商户实行简易申报的，2020年5月1日至2020年12月31日期间暂不扣划个人所得税，延迟至2021年首个申报期内一并划缴。
  <br>
  <br>
  三是个体工商户延缓缴纳个人所得税的时间。享受延缓缴纳政策的个体工商户，暂缓缴纳的税款，延迟至2021年首个申报期内一并缴纳。如纳税人因买房、买车、积分落户等特殊需要，也可根据自身情况选择不享受延缓缴纳税款。
  <br>
  <br>
  （三）实施时间
  <br>
  <br>
  《公告》自2020年5月1日起施行。5月1日至本公告发布前，纳税人已经缴纳符合本公告规定缓缴税款的，可申请退还，一并至2021年首个申报期内缴纳。
  `,
  `
  减税降费政策操作指南来啦~
  <br>
  <br>
  还有人不清楚的吗？
  <br>
  <br>
  视频、文字双管齐下
  <br>
  <br>
  保证看得明明白白！
  <br>
  <br>
  减税降费政策解读
  <br>
  <br>
  part 1 月销售额15万元以下免征增值税
  <br>
  <br>
  自2021年4月1日至2022年12月31日，小规模纳税人发生增值税应税销售行为，合计月销售额未超过15万元，或者以1个季度为1个纳税期的，季度销售额未超过45万元的，免征增值税。
  <br>
  <br>
  纳税人在一个纳税期内销售额超过免征限额，但扣除本期发生的销售不动产的销售额后未超过限额的，其销售货物、劳务、服务、无形资产取得的销售额免征增值税。
  <br>
  <br>
  如果销售额未超过免征限额，但已就部分销售额开具了增值税专用发票，未开具增值税专用发票的销售额仍可享受免征政策，开具增值税专用发票的销售额若要享受免征政策，需要按规定将增值税专用发票作废或者开具红字专用发票后才能享受。
  <br>
  <br>
  享受政策很简单
  <br>
  <br>
  填写《增值税及附加税费申报表（小规模纳税人适用》相应栏次即可：
  <br>
  <br>
  企业享受本项优惠的，填写第10行“小微企业免税销售额”；个体工商户和其他个人享受本项优惠的，填写第11行“未达起征点销售额”。
  <br>
  <br>
  如果您是按照现行规定应当预缴增值税税款的小规模纳税人，在预缴地实现的月销售额未超过15万元的，当期无需预缴税款。
  <br>
  <br>
  如果您是除个体工商户以外的其他个人，采取一次性收取租金形式出租不动产取得的租金收入，可在对应的租赁期内平均分摊，分摊后的月租金收入未超过15万元的，也可免征增值税。
  <br>
  <br>
  <br>
  <br>
  温馨提示：
  <br>
  <br>
  按固定期限纳税的小规模纳税人可以选择以1个月或1个季度为纳税期限，一经选择，一个会计年度内不得变更。
  <br>
  <br>
  part 2 小型微利企业减征企业所得税政策
  <br>
  <br>
  自2019年1月1日至2021年12月31日，对小型微利企业年应纳税所得额不超过100万元的部分，减按25%计入应纳税所得额，按20%的税率缴纳企业所得税；对年应纳税所得额超过100万元但不超过300万元的部分，减按50%计入应纳税所得额，按20%的税率缴纳企业所得税。
  <br>
  <br>
  其中，自2021年1月1日至2022年12月31日，对小型微利企业年应纳税所得额不超过100万元的部分，减按12.5%计入应纳税所得额，按20%的税率缴纳企业所得税。
  <br>
  <br>
  申报享受很容易
  <br>
  <br>
  企业享受企业所得税优惠事项均采取“自行判别、申报享受、相关资料留存备查”的办理方式，小型微利企业在预缴和汇算清缴所得税时均可享受减免政策。
  <br>
  <br>
  在预缴申报时，企业应通过《中华人民共和国企业所得税月（季）度预缴纳税申报表（A类）》（A200000）第13行“减：减免所得税额”下的明细行次填报“符合条件的小型微利企业减免企业所得税”事项，或在《中华人民共和国企业所得税月（季）度预缴和年度纳税申报表（B类，2018版）》（B100000）第17行“减：符合条件的小型微利企业减免企业所得税”填报。
  <br>
  <br>
  在汇算清缴申报时，企业应在《中华人民共和国企业所得税年度纳税申报表（A类，2017版）》（A107040）第1行“符合条件的小型微利企业减免企业所得税”，或《中华人民共和国企业所得税月（季）度预缴和年度纳税申报表（B类，2018版）》（B100000）第17行“减：符合条件的小型微利企业减免企业所得税”填报。
  <br>
  <br>

  科普小知识：
  <br>
  <br>
  小型微利企业是从事国家非限制和禁止行业，且同时符合年度应纳税所得额不超过300万元、从业人数不超过300人、资产总额不超过5000万元等三个条件的企业。
  <br>
  <br>
  温馨提示：
  <br>
  <br>
  预缴企业所得税时，小型微利企业的资产总额、从业人数、年度应纳税所得额指标，暂按当年度截至本期申报所属期末的情况进行判断。
  <br>
  <br>
  对于通过电子税务局申报的小型微利企业，申报系统将根据申报表相关数据，自动判断企业是否符合小型微利企业条件；符合条件的，申报系统还将进一步自动计算减免税金额并生成表单，纳税人确认即可。
  <br>
  <br>
  part 3 适用3%征收率的减按1%征收增值税
  <br>
  <br>
  自2020年3月1日至2021年12月31日，增值税小规模纳税人，适用3%征收率的应税销售收入，减按1%征收率征收增值税；适用3%预征率的预缴增值税项目，减按1%预征率预缴增值税。其中，自2020年3月1日至2021年3月31日，对湖北省增值税小规模纳税人，适用3%征收率的应税销售收入，免征增值税；适用3%预征率的预缴增值税项目，暂停预缴增值税。
  <br>
  <br>
  申报享受很便捷
  <br>
  <br>
  纳税人在增值税纳税申报时按规定填写申报表即可享受优惠。
  <br>
  <br>
  减按1%征收率征收增值税的销售额填写在《增值税及附加税费申报表（小规模纳税人适用）》“应征增值税不含税销售额（3%征收率）”相应栏次，对应减征的增值税应纳税额按销售额的2%计算填写在《增值税及附加税费申报表（小规模纳税人适用）》“本期应纳税额减征额”及《增值税减免税申报明细表》减税项目相应栏次。
  <br>
  <br>
  销售额=含税销售额/（1+1%）
  <br>
  <br>

  没有开具3%征收率增值税专用发票的部分，仍可以享受减按1%征收率缴纳增值税政策。
  <br>
  <br>

  已经按照3%征收率开具了增值税专用发票的应税收入，若要享受减按1%征收率缴纳增值税政策，需要按规定将增值税专用发票作废或者开具红字专用发票。
  <br>
  <br>
  开具红字发票流程：开具纸质增值税专用发票的，根据不同情况，由购买方或者销售方在增值税发票管理系统中填开并上传《开具红字增值税专用发票信息表》，销售方凭税务机关系统自动校验通过的信息表，在增值税发票管理系统中以销项负数开具红字专用发票。若开具的是增值税电子专用发票，除无需追回已经开具的蓝字电子专票外，其他流程与纸质发票流程基本一致。
  `,
  `
  近日，国家税务总局推出最新一期“税务讲堂”课程，国家税务总局所得税司副司长王海勇介绍并解读了小型微利企业和个体工商户所得税优惠政策。今天我们学习：小型微利企业和个体工商户如何享受企业所得税优惠政策？
  <br>
  <br>
  小型微利企业如何享受企业所得税优惠政策？
  <br>
  <br>
  为了推进办税便利化改革，小型微利企业统一实行按季度预缴企业所得税。小型微利企业在预缴和汇算清缴所得税时均可享受优惠政策，享受政策时无需备案，通过填写企业所得税纳税申报表相关栏次，即可享受。
  <br>
  <br>
  对于通过电子税务局申报的小型微利企业，纳税人只需要填报从业人数、资产总额等基础信息，税务机关将为纳税人提供自动识别、自动计算、自动填报的智能服务，进一步减轻纳税人填报负担。
  <br>
  <br>
  个体工商户如何享受减半征收所得税优惠政策?
  <br>
  <br>
  为方便纳税人，国家税务总局公告2021年第8号规定个体工商户在预缴和汇算清缴时都可以享受优惠政策。在具体的享受方式上，我们切实优化政策享受流程，确保减税政策落实到位。
  <br>
  <br>
  一是对于采用纸质申报表申报缴税的个体工商户，其在进行纳税申报时，将计算得出的减免税额填入对应经营所得纳税申报表“减免税额”栏次，并附报《个人所得税减免税事项报告表》即可享受。
  <br>
  <br>
  二是对于通过电子税务局申报的个体工商户，税务机关为其提供了该优惠政策减免税额和报告表的预填服务，纳税人在申报时只需要核对相关信息、确认减免税额无误后即可方便快捷完成申报。
  <br>
  <br>
  三是对于实行定期定额征收的个体工商户，其享受政策的流程也非常方便快捷，税务机关将在原定额的基础上计算税收优惠，并按照减免后的税额进行税款划缴。
  `,
  `
  申报类问题
  <br>
  <br>
  1.我在按期应申报界面申报《消费税及附加税费申报表》后还未缴税，发现消费税显示已申报，附加税费显示未申报，应如何处理？
  <br>
  <br>
  答：根据国家税务总局发布的《关于增值税 消费税与附加税费申报表整合有关事项的公告》（国家税务总局公告2021年第20号）要求，明确自2021年8月1日起，全面推行增值税、消费税分别与附加税费申报表整合工作。在【按期应申报】界面申报《消费税及附加税费申报表》后，附加税费显示未申报，可能情况为：新核定消费税附征的附加税费后，《消费税及附加税费申报表》因未点击重置导致未带出附加税费计算表。如出现该问题，请点击【我要办税】—【税费申报及缴纳】—【申报作废】模块，作废《消费税及附加税费申报表》，再重新进入【按期应申报】模块，进入点击填写申报表，检查报表列表中是否带出《消费税附加税费计算表》，如果未带出，请点击填表界面右上角“重置”，带出这张表后再进行申报。
  <br>
  <br>
  2.我们协会在申报2020年残保金时，上年在职职工人数只有1人，系统显示还是需要缴费，为什么没有按照小微政策全免呢？
  <br>
  <br>
  答：自2020年1月1日起至2022年12月31日，在职职工人数在30人（含）以下的企业，暂免征收残保金。该条减免性质适用于企业，企业与非企业以登记注册类型来划分。请核实缴费人的登记注册类型，如为非企业，比如社会团体、非企业单位、事业单位等，不适用小微企业减免政策，按照2020年1月1日起至2022年12月31日的残保金分档减缴政策计算缴纳。
  <br>
  <br>
  国际类问题
  <br>
  <br>
  我公司只代扣代缴增值税，请问源泉扣缴合同如何申报？
  <br>
  <br>
  答：源泉合同已无需备案，可直接在申报时新增合同后完成申报。如您只代扣代缴增值税，请通过电子税务局【我要办税】—【税费申报及缴纳】—【其他申报】—【增值税代扣代缴、代收代缴申报】模块操作，在《代扣代缴、代收代缴明细报告表》中选择增值税的征收品目后，系统自动带出合同采集界面，点击新增合同，采集源泉合同信息后保存，选择该条合同信息完成申报即可。
  `,
]


export const pageSeoInfo = {
  home:{
    title:'注册公司_公司起名_公司注册代理-十角兽官网',
    keywords:'注册公司,公司注册,公司注册代理,公司注册代办,公司起名,起名公司,十角兽',
    description:'海南十角兽网络科技有限公司是专业的注册公司代理及公司起名的企业,为广大创业者、中小型企业提供专业的注册代理服务.多年企业服务经验,专业化团队办理,值得您的信赖.'
  },
  product:{
    title:'新公司注册_代理注册公司_注册公司代办-十角兽官网',
    keywords:'新公司注册,代理注册公司,注册公司代办, 公司注册流程',
    description:'十角兽专注于为企业提供新公司注册,代理注册公司,注册公司代办等企业服务,积累多年注册代理服务经验,诚信可靠,快速高效.'
  },
  partner:{
    title:'注册公司代理_注册公司流程_代理公司注册-十角兽官网',
    keywords:'注册公司代理,注册公司流程,代理公司注册,代办注册公司',
    description:'十角兽专业提供注册公司代理,代理公司注册,注册公司流程及费用,工商营业执照代理,财务代理等众多业务,找十角兽代办让创业变得更简单.'
  },
  news:{
    title:'公司取名_公司起名字_公司取名大全-十角兽官网',
    keywords:'公司取名,公司起名字,公司取名大全',
    description:'海南十角兽,是专业给公司取名大全的网站,擅长公司企业起名、有各行业取名经验,提供免费起名字参考,打造起名行业诚信口碑.'
  },
  case:{
    title:'公司测名,公司测名字,公司取名字,十角兽官网',
    keywords:'公司取名,公司起名字,公司取名大全',
    description:'十角兽公司取名网,专业取名网站,免费为广大创业老板提供公司起名,公司取名,公司测名,企业名称注册查询系统精准对接工商大数据,解决公司起名核名、商标查询、经营范围生成等新注册企业遇到的工商问题.'
  },
  download:{
    title:'注册公司_公司起名_公司注册代理-十角兽官网',
    keywords:'注册公司,公司注册,公司注册代理,公司注册代办,公司起名,起名公司,十角兽',
    description:'海南十角兽网络科技有限公司是专业的注册公司代理及公司起名的企业,为广大创业者、中小型企业提供专业的注册代理服务.多年企业服务经验,专业化团队办理,值得您的信赖.'
  },
}
// router.beforeEach((to, from, next) => {
//   if (to.name.indexOf('home') !== -1) {
//     document.querySelector('meta[name="keywords"]').setAttribute('content', '注册公司,公司注册,公司注册代理,公司注册代办,公司起名,起名公司,十角兽')
//     document.querySelector('meta[name="description"]').setAttribute('content', '海南十角兽网络科技有限公司是专业的注册公司代理及公司起名的企业,为广大创业者、中小型企业提供专业的注册代理服务.多年企业服务经验,专业化团队办理,值得您的信赖.')
//     document.title = '注册公司_公司起名_公司注册代理-十角兽';
//   } else if (to.name.indexOf('product') !== -1) {
//     document.querySelector('meta[name="keywords"]').setAttribute('content', '新公司注册,代理注册公司,注册公司代办, 公司注册流程')
//     document.querySelector('meta[name="description"]').setAttribute('content', '十角兽专注于为企业提供新公司注册,代理注册公司,注册公司代办等企业服务,积累多年注册代理服务经验,诚信可靠,快速高效.')
//     document.title = '新公司注册_代理注册公司_注册公司代办';
//   } else if (to.name.indexOf('partner') !== -1) {
//     document.querySelector('meta[name="keywords"]').setAttribute('content', '注册公司代理,注册公司流程,代理公司注册,代办注册公司')
//     document.querySelector('meta[name="description"]').setAttribute('content', '十角兽专业提供注册公司代理,代理公司注册,注册公司流程及费用,工商营业执照代理,财务代理等众多业务,找十角兽代办让创业变得更简单.')
//     document.title = '注册公司代理_注册公司流程_代理公司注册';
//   } else if (to.name.indexOf('news') !== -1) {
//     document.querySelector('meta[name="keywords"]').setAttribute('content', '公司取名,公司起名字,公司取名大')
//     document.querySelector('meta[name="description"]').setAttribute('content', '海南十角兽,是专业给公司取名大全的网站,擅长公司企业起名、有各行业取名经验,提供免费起名字参考,打造起名行业诚信口碑.')
//     document.title = '公司取名_公司起名字_公司取名大全';
//   } else if (to.name.indexOf('case') !== -1) {
//     document.querySelector('meta[name="keywords"]').setAttribute('content', '公司测名,公司测名字,公司取名字')
//     document.querySelector('meta[name="description"]').setAttribute('content', '十角兽公司取名网,专业取名网站,免费为广大创业老板提供公司起名,公司取名,公司测名,企业名称注册查询系统精准对接工商大数据,解决公司起名核名、商标查询、经营范围生成等新注册企业遇到的工商问题.')
//     document.title = '公司测名_公司测名字_公司取名字';
//   }
//   next()
// })
