import { http, sjsgw } from '../http/http'
let sjsgwUrl = sjsgw
if (window.location.href.indexOf('qywx.yunzhangfang.cn') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('172.23.220.101') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('qyunf.com') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('shijiaoshou.cn') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('sjsdz.cn') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('sjscs.cn') !== -1) {
  sjsgwUrl = ''
}


const getDemo = (item) => {
  return http({
    url: sjsgwUrl + '/demo',
    method: 'GET',
    params: item,
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

const postDemo = (item) => {
  return http({
    url: sjsgwUrl + '/demo',
    method: 'POST',
    data: item,
  }).then((res) => res.data)
}
//批量保存
const batchSave = (item) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/log/app/batchSave',
    method: 'POST',
    data: item,
  }).then((res) => res.data)
}
//获取二维码
const getGwQrCode = () => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/sjs/gw/getGwQrCode',
    method: 'GET',
    params: {},
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

//获取登录信息
const getLoginInfo = (params) => {
  return http({
    url:
      sjsgwUrl +
      '/api/fintax/application/auto/authority/user/getCustomerOnlyKey',
    method: 'GET',
    params,
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

//企业起名
const companyQm = (item) => {
  return http({
    url:
      sjsgwUrl +
      '/api/fintax/application/auto/gszc/registration/qmcm/companyQm',
    method: 'POST',
    data: item,
  }).then((res) => res.data)
}

//获取城市
const getAllNodeTree = () => {
  return http({
    url:
      sjsgwUrl + '/api/fintax/application/auto/gszc/qhm/getAllNodeTreeForWeb',
    method: 'GET',
    params: {},
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

//获取行业
const getIndustry = (params) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/qhm/getIndustry',
    method: 'GET',
    params,
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

//企业测名
const companyCm = (item) => {
  return http({
    url:
      sjsgwUrl +
      '/api/fintax/application/auto/gszc/registration/qmcm/companyCm',
    method: 'POST',
    data: item,
  }).then((res) => res.data)
}

//企业核名
const checkName = (item) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/pthm/checkName',
    method: 'POST',
    data: item,
  }).then((res) => res.data)
}

export default {
  getGwQrCode,
  getLoginInfo,
  companyQm,
  getAllNodeTree,
  getIndustry,
  companyCm,
  checkName,
  batchSave,
}
